import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/7ETdoUJkjo0">
    <SEO title="The Bigger Picture - Counter Culture" />
  </Layout>
)

export default SermonPost
